body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 2px solid #009882;
  padding: 1% 5%;
  border-radius: 6px;
  color: white;
  background-color: #009882;
  cursor: pointer;
}
