/* Your CSS file (e.g., MyLayoutComponent.css) */

body,
html {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center;*/
}

.vertical-card {
  width: 30%;
  background-color: #fff; /* Change the color as needed */
  padding: 10px; /* Add padding as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for elevation */
  border-radius: 10px; /* Add border-radius for rounded corners */
}

.profile-image {
  width: 100px; /* Adjust the size of the circular image */
  height: 100px; /* Adjust the size of the circular image */
  background-color: #000; /* Change the background color as needed */
  border-radius: 50%; /* Create a circular shape */
  margin-bottom: 10px; /* Adjust margin as needed */
}

.profile-card {
  height: fit-content !important;
}

.text-fields {
  text-align: center;
  color: #000; /* Change the text color as needed */
}

.horizontal-cards {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.horizontal-card {
  width: 98%; /* Set width to 98% */
  height: calc(800px / 3); /* 800px / 3 = 266px */
  background-color: #fff; /* Change the color as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for elevation */
  border-radius: 10px; /* Add border-radius for rounded corners */
 
}

.smaller-cards {
  display: flex;
  justify-content: space-between;
}

.small-card {
  width: calc(33.33% - 5px); /* Adjust the width of each smaller card */
  height: 100px; /* Adjust the height of each smaller card */
  background-color: #000; /* Change the background color as needed */
  border-radius: 10px; /* Add border-radius for rounded corners */
  
}

.smaller-cards2 {
  display: flex;
  justify-content: space-evenly;
}

.small-card2 {
  width:48%; /* Adjust the width of each smaller card */
  height: 80px; /* Adjust the height of each smaller card */
  background-color: #fff; /* Change the background color as needed */
  border-radius: 10px; /* Add border-radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for elevation */
  border-radius: 10px;
}


@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .horizontal-card,
  .vertical-card {
    width: 100%;
  }

  .horizontal-card {
    padding: 10px; /* Adjust padding as needed */
  }

  .small-card {
    width: 100%; /* Adjust width for smaller screens */
    margin-bottom: 10px; /* Add margin between smaller cards */
  }
}

.padding-profile{
  padding-left: 2%;
}

.doctor-card {
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 15%;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  align-items: baseline;

}

.column1 {
  flex: 1;
  transform: translateX(50%);
}


.horizontal-cards-2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.horizontal-card-2 {
  width: 98%; /* Set width to 98% */
  height: calc(800px / 3); /* 800px / 3 = 266px */
 
}
