/* CardContainer.css */

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Adjust as needed based on your design requirements */
  }
  
  .card {
    width: calc(25% - 10px); /* Adjust the width and margin as needed */
    margin: 10px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-number {
    font-size: 1.2rem;
    margin-right: 8px;
  }
  
  .card-icon {
    font-size: 1.5rem;
  }
  
  .card-title {
    margin-top: 8px;
  }
  
  /* Media query to handle responsive design */
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 10px);
    }
  }
  